
$( document ).ready(function() {
    var cookiesAccepted = window.localStorage.getItem("cookies-acknowledged");
    var cookieBanner = $( "#cookie-info-banner" );

    if(cookiesAccepted!=="true") {
        cookieBanner.show();
        $( "#footer" ).addClass('cookie-info-banner-extra-padding');
        $( "#cookie-info-banner").find("#closeCookieBannerButton" ).on( "click", function( event ) {
            window.localStorage.setItem("cookies-acknowledged", true);
            cookieBanner.hide();
            $( "#footer" ).removeClass('cookie-info-banner-extra-padding');
        });
    }

});