var openingTimes = [];
var closedDays = [];
var openClosed = {
    open: '<span class="open">Wij zijn nu open!</span>',
    closed: '<span class="closed">Helaas, wij zijn nu gesloten</span>'
};

$( document ).ready(function() {
    $.getJSON('/data/opening-hours.json', function(data) {
        //console.log("Loaded: %o", data);
        openingTimes = data.openingTimes;
        closedDays = data.closedDays;
        updateOpenOrClosedStatus();
    });
});

function updateOpenOrClosedStatus() {
    var now = new Date();
    var status = openClosed.closed;
    var transitionTime = null;
    var openingTimesForDay = findOpeningTimes(now);
    //console.log("DATE: %o,  day: %o, hr: %o, min: %o, otfd: %o", now, now.getDay(), now.getHours(), now.getMinutes(), openingTimesForDay);
    if(openingTimesForDay!=null) {
        var timeOpen = toOpenOrClosingTime(openingTimesForDay.open);
        var timeClosed = toOpenOrClosingTime(openingTimesForDay.close);
        //console.log("OT: open: %o, close: %o", timeOpen, timeClosed);
        if(timeOpen <= now && now < timeClosed) {
            status = openClosed.open;
            transitionTime = timeClosed;
        }
        else if(now < timeOpen) {
            transitionTime = timeOpen;
        }
    }
    
    if(transitionTime==null) {
        var nextOpeningTimes = null;
        var days = 0;
        while(nextOpeningTimes==null && days < 7) {
            days += 1;
            var tgt = new Date(now);
            tgt.setDate(tgt.getDate() + days);
            nextOpeningTimes = findOpeningTimes(tgt);
        }
        var timeOpen = toOpenOrClosingTime(nextOpeningTimes.open);
        timeOpen.setDate(timeOpen.getDate() + days);
        transitionTime = timeOpen; 
    }

    $('.open-or-closed').each(function(index, element) {
        //console.log('EACH %o %o', index, element, $(element));
        element.innerHTML = status;
    });
    
    var sleepTime = transitionTime - new Date();
    //console.log("Sleeptime: %o -> %o min -> %o hr", sleepTime, sleepTime / 60000, sleepTime / 3600000);
    setTimeout(updateOpenOrClosedStatus, sleepTime);
}

function findOpeningTimes(day) {
    for(var i=0 ; i<closedDays.length; i++) {
        var elems = closedDays[i].date.split('-');
        if(Number(elems[0])==day.getFullYear() && Number(elems[1])==(day.getMonth()+1) && Number(elems[2])==day.getDate()) {
            return null;
        }
    }
    for(var j=0 ; j<openingTimes.length; j++) {
        if(day.getDay()==openingTimes[j].day) {
            return openingTimes[j];
        }
    }
    return null;
}

function toOpenOrClosingTime(time) {
    var elems = time.split(':');
    var date = new Date();
    date.setHours( Number(elems[0]) );
    date.setMinutes( Number(elems[1]) );
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}