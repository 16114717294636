

function startPage() {
    $('#layerslider').layerSlider({
        skinsPath : 'layerslider/skins/',
        skin : 'florida',
        navStartStop        : false,
        thumbnailNavigation : 'hover',
        hoverPrevNext : true,
        responsive : true,
        responsiveUnder : 1200,
        thumbnailNavigation : false,
        sublayerContainer : 1200
    });
}

$( document ).ready(function() {
    startPage();
});


jQuery(document).ready(function() {
    jQuery(window).scroll(function() {
        if(jQuery(this).scrollTop()>220) {
            var bc = jQuery(".breadcrumb");
            bc.addClass("breadcrump-sticky");
        }
        else {
            var bc = jQuery(".breadcrumb");
            bc.removeClass("breadcrump-sticky");
        }
    });
});