
$( document ).ready(function() {
    var vacancyBannerHidden = window.localStorage.getItem("vacancy-banner-hidden");
    var vacancyBanner = $( "#vacature-banner" );

    if(vacancyBannerHidden!=="true") {
        vacancyBanner.show();
        $( "#footer" ).addClass('vacancy-banner-extra-padding');
    }
    $( "#closeVacancyBannerButton" ).on( "click", function( event ) {
        window.localStorage.setItem("vacancy-banner-hidden", true);
        vacancyBanner.hide();
        $( "#footer" ).removeClass('vacancy-banner-extra-padding');
    });

});
